tr:nth-child(2n) {
  background-color: #f7f7f7;
}

.MuiTableCell-sizeSmall {
  padding: 0px 5px 0px 5px !important;
}

.css-kj6f9i-menu {
}
